import { track } from '@amplitude/analytics-browser';

/**
 * Represents a trackable event.
 * @class
 */
class TrackableEvent {
    /**
     * Creates a new TrackableEvent instance.
     * @constructor
     * @param {Object} options - The options for the trackable event.
     * @param {string} options.pageName - The name of the page.
     * @param {string} options.clientCode - The client code.
     * @param {string} options.action - The action associated with the event.
     * @param {Object} [options.additionalMetadata={}] - Additional metadata for the event.
     * @param {string|null} [options.overrideMessage=null] - An optional override message for the event.
     */
    constructor({ pageName, clientCode, action, additionalMetadata = {}, overrideMessage = null }) {
        this.pageName = pageName;
        this.clientCode = clientCode;
        this.action = action;
        this.additionalMetadata = additionalMetadata;
        this.overrideMessage = overrideMessage;
    }
}

const checkEnv = () => {
    // TODO: put this back in after testing in staging
    // if (process.env.REACT_APP_APP_ENV !== 'production') {
    //     console.log(`Skip tracking event due to env: ${eventName}`);
    //     return false
    // }

    return true
}

const trackBasicEvent = (eventName) => {
    if (!checkEnv()) {
        return
    }
    
    track(eventName);
}

const trackEvent = (eventName, eventData) => {
    if (!checkEnv()) {
        return
    }

    track(eventName, eventData);
}

/**
 * Track an event from the trackableEvent object
 * 
 * @param {TrackableEvent} trackableEvent 
 * @returns 
 */
const trackEventFromObject = (trackableEvent) => {
    if (!checkEnv()) {
        return
    }

    trackEvent(trackableEvent.pageName + ' Event', {
        action: trackableEvent.action,
        clientCode: trackableEvent.clientCode,
        ...trackableEvent.additionalMetadata,
    });
}

const handleTrackError = ({ error, pageName = null, overrideMessage = null, additionalMetadata = {} }) => {
    if (!checkEnv()) {
        return
    }

    let message = (error.response?.data?.message || overrideMessage) || 'Unknown error';

    let metadata = {pageName, errorMessage: message};
    if (additionalMetadata) {
        metadata = {...metadata, ...additionalMetadata}
    }

    trackEvent(`${pageName ?? 'General'} Error`, metadata);
}

/**
 * Takes in a TrackableEvent object and sends an event to Amplitude
 * 
 * @param {TrackableEvent} trackableEvent 
 */
const trackEventFromErrorObject = (trackableEvent) => {
    if (!checkEnv()) {
        return
    }

    let message = (trackableEvent.error.response?.data?.message || trackableEvent.overrideMessage) || 'Unknown error';

    trackEvent(trackableEvent.overrideMessage ?? `${trackableEvent.pageName} Error`, {
        action: trackableEvent.action,
        errorMessage: message,
        clientCode: trackableEvent.clientCode,
        ...trackableEvent.additionalMetadata,
    });
}

export {
    trackBasicEvent,
    trackEvent,
    handleTrackError,
    trackEventFromObject,
    trackEventFromErrorObject,
    TrackableEvent,
}