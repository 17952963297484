import * as React from 'react';
import {
	AppBar,
	CssBaseline,
	Box,
	Breadcrumbs,
	Paper,
	Menu,
	MenuItem,
	MenuList,
	IconButton,
	Divider,
	Button,
	Typography,
} from "@mui/material"
import {
	Link,
	useNavigate,
	useLocation,
	useParams,
} from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Auth from '../../providers/AuthProvider'
import MenuIcon from '@mui/icons-material/Menu';
import HouseSidingTwoToneIcon from '@mui/icons-material/HouseSidingTwoTone';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClientSelect from './ClientSelect'

const cssInJs = {
  header: {
  	height: "50px",
  	background: '#4d3660',
  	color: 'white',
  	alignItems: 'space-between',
  	flexDirection: 'row',
  	borderRadius: 0,
  	overflow: 'visible',
  	"._logo": {
  		height: '100%',
  		display:{xs:'none', lg:'block'}
  	}
  },
  menuIcon: {
  	width: '80px',
  	textAlign:'center'
  },
  userInfoSection: {
  	mr: {xs:1, sm:2},
  	ml: 1,
  	fontSize: 12,
  	height: '50px',
  	borderRadius: 0,
  	borderLeft: '1px solid rgba(255,255,255,0.1)'
  },
  clientChooseSection: {
  	flex: 1,
  	display: 'flex',
  },
  profileItemButton: {
  	color: 'white'
  }
}

function TopAppBar(props) {
	let [openAppSelector, setOpenAppSelector] = React.useState(false)
	let [openUserInfo, setOpenUserInfo] = React.useState(false)
	let [openSettingMenu, setOpenSettingMenu] = React.useState(false)
	const appAnchor = React.useRef(null)
	const userInfoAnchor = React.useRef(null)
	const settingButtonAnchor = React.useRef(null)
	const navigate = useNavigate()
	let auth = Auth.useAuth()
	let location = useLocation()

	React.useEffect(() => {
	}, [])

	function toggleAppSelector() {
		setOpenAppSelector(!openAppSelector)
	}

	function handleCloseAppSelector() {
		setOpenAppSelector(false)	
	}

	function toggleUserInfoMenu() {
		setOpenUserInfo(!openUserInfo)
	}

	function selectApp(app_id) {
		handleCloseAppSelector()
		navigate(`/apps/${app_id}/dashboard`)
	}

	function handleCloseUserInfo() {
		setOpenUserInfo(false)	
	}

	async function logOut() {
		await auth.logout()
	}

	function toProfilePage() {
		handleCloseUserInfo()
		navigate(`/users/${auth.user.id}`)
	}

	function unspoof() {
		auth.spoofBack()
		setOpenUserInfo(false)
	}

	function toggleSettingMenu() {
		setOpenSettingMenu(!openSettingMenu)
	}

	return <AppBar position="fixed" sx={cssInJs.header}>
			<IconButton
        size="large"
        color="inherit"
        sx={cssInJs.menuIcon}
      >
        <MenuIcon />
      </IconButton>
			<Box sx={cssInJs.clientChooseSection}>
				<img src="/images/romain.png" className="_logo"/>
				<ClientSelect />
			</Box>
			<Box sx={cssInJs.profileSection}>
				{/* <IconButton sx={cssInJs.profileItemButton}><AccountBalanceTwoToneIcon /></IconButton>
				<IconButton sx={cssInJs.profileItemButton}><EventNoteTwoToneIcon /></IconButton>
				<IconButton sx={cssInJs.profileItemButton}><AttachMoneyTwoToneIcon /></IconButton> */}
				{auth.checkPermission(['user-page-show','role-assign-user','permission-page-show']) && 
					<IconButton sx={cssInJs.profileItemButton} ref={settingButtonAnchor} onClick={toggleSettingMenu}><SettingsOutlinedIcon /></IconButton>
				}
				<Menu
					open={openSettingMenu}
					anchorEl={settingButtonAnchor.current}
					onClose={e => {setOpenSettingMenu(false)}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        	anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					{auth.checkPermission('user-page-show') &&
						<MenuItem onClick={e => {setOpenSettingMenu(false)}} dense component={Link} to="/users">
							Users
						</MenuItem>
					}
					{auth.checkPermission('role-assign-user') &&
						<MenuItem onClick={e => {setOpenSettingMenu(false)}} dense component={Link} to="/admin/user-roles">
							User Roles
						</MenuItem>
					}
					{auth.checkPermission('permission-page-show') &&
						<MenuItem onClick={e => {setOpenSettingMenu(false)}} dense component={Link} to="/admin/permissions">
							Roles & Permissions
						</MenuItem>
					}
					{auth.checkPermission('permission-page-show') &&
						<MenuItem onClick={e => {setOpenSettingMenu(false)}} dense component={Link} to="/admin/flightcontrol">
							Flight Control
						</MenuItem>
					}
				</Menu>

				{/* <IconButton sx={cssInJs.profileItemButton}><NotificationsOutlinedIcon /></IconButton> */}

				<Button
					size="large"
					onClick={toggleUserInfoMenu}
					color="inherit"
					sx={cssInJs.userInfoSection}
					ref={userInfoAnchor}
				>
					<AccountCircleTwoToneIcon sx={{fontSize: '26px', mr:1,}} />
					{auth.user.email}
				</Button>
				<Menu
					open={openUserInfo}
					anchorEl={userInfoAnchor.current}
					onClose={handleCloseUserInfo}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        	anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        	PaperProps={{
	          elevation: 0,
	          sx: {
	          	minWidth: '150px',
	            overflow: 'visible',
	            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
	            '&:before': {
	              content: '""',
	              display: 'block',
	              position: 'absolute',
	              top: 0,
	              right: 14,
	              width: 10,
	              height: 10,
	              bgcolor: 'background.paper',
	              transform: 'translateY(-50%) rotate(45deg)',
	              zIndex: 0,
	            },
	          },
	        }}
				>
					<MenuItem onClick={toProfilePage} dense>
						<PermIdentityOutlinedIcon sx={{mr:1}}/> 
						Profile
					</MenuItem>

					<Divider sx={{ my: 0.5 }} />
					<MenuItem onClick={logOut} dense>
						<LogoutOutlinedIcon sx={{mr:1}}/> 
						Logout
					</MenuItem>
					{auth.spoofing && 
						<MenuItem onClick={unspoof} dense>
							<PublishedWithChangesOutlinedIcon sx={{mr:1}}/> 
							Unspoof
						</MenuItem>
					}
				</Menu>
			</Box>
    </AppBar>
}
export default TopAppBar